import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styles } from '../styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuGroupItem } from './MenuGroupItem';
import useAuth from 'src/hooks/useAuth';
import { IGroupMenu, IMenuItem } from './MenuItems';

export const MenuItem: FC<{
  id: string;
  item: IMenuItem;
  openMenu: string;
  setOpen: any;
}> = ({ openMenu, item, setOpen, id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();
  const [openCollapse, setOpenCollapse] = useState('');

  const toggleClick = (path: string) => {
    if (openMenu === path) {
      setOpen('');
    } else {
      setOpen(path);
    }
  };

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.role);
  };

  const handleClickLink = (menuItem: IMenuItem) => {
    navigate(menuItem.path);
  };

  const isActive = (path: string) => {
    return location?.pathname === path;
  };

  return (
    <>
      {!item.subMenu ? (
        <ListItemButton
          key={id}
          sx={[
            styles.menuItemButton,
            { display: isRightUser(item.users) ? 'flex' : 'none' }
          ]}
          className={isActive(item.path) ? 'active' : ''}
          onClick={() => {
            handleClickLink(item);
            setOpen('');
          }}
        >
          <ListItemIcon sx={styles.menuItemIcon}>
            <item.Icon />
          </ListItemIcon>
          <ListItemText sx={styles.menuItemText} primary={item.title} />
        </ListItemButton>
      ) : (
        <Fragment key={id}>
          <ListItemButton
            sx={[
              styles.menuItemButton,
              { display: isRightUser(item.users) ? 'flex' : 'none' }
            ]}
            onClick={() => toggleClick(item.path)}
          >
            <ListItemIcon sx={styles.menuItemIcon}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText sx={styles.menuItemText} primary={item.title} />

            {openMenu === item.path ? (
              <ExpandLessIcon sx={{ color: '#dfdfdf', fontSize: '16px' }} />
            ) : (
              <ExpandMoreIcon sx={{ color: '#dfdfdf', fontSize: '16px' }} />
            )}
          </ListItemButton>

          <Collapse
            sx={styles.collapseContainer}
            in={openMenu === item.path}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.subMenu?.map((subItem: IMenuItem, index: number) => (
                <MenuGroupItem
                  key={`${subItem.title}sub-${index}`}
                  item={subItem as IGroupMenu}
                  openMenu={openCollapse}
                  setOpen={setOpenCollapse}
                />
              ))}
            </List>
          </Collapse>
        </Fragment>
      )}
    </>
  );
};
