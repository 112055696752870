import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { styles } from '../styles';
import useAuth from 'src/hooks/useAuth';
import { IGroupMenu, IMenuItem } from './MenuItems';

export const MenuGroupItem: FC<{
  item: IGroupMenu;
  openMenu: string;
  setOpen: any;
}> = ({ openMenu, item, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();

  const toggleClick = (path: string) => {
    if (openMenu === path) {
      setOpen('');
    } else {
      setOpen(path);
    }
  };

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  const handleClickLink = (menuItem: IMenuItem) => {
    navigate(menuItem.path);
  };

  const isActive = (path: string) => {
    return location?.pathname === path;
  };

  return (
    <>
      {item.isGroup ? (
        <>
          <ListItemButton
            sx={[
              styles.subMenuGroupButton,
              {
                display: isRightUser(item.users) ? 'flex' : 'none'
              }
            ]}
            onClick={() => toggleClick(item.path)}
          >
            <ListItemText sx={styles.menuItemText} primary={item.title} />
            {openMenu === item.path ? (
              <ExpandLessIcon sx={{ color: '#dfdfdf', fontSize: '16px' }} />
            ) : (
              <ExpandMoreIcon sx={{ color: '#dfdfdf', fontSize: '16px' }} />
            )}
          </ListItemButton>
          <Collapse
            sx={styles.groupCollapseContainer}
            in={openMenu === item.path}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.menuItems?.map((subItem: IMenuItem, index: number) => (
                <ListItemButton
                  key={`${subItem.title}sub-${index}`}
                  className={isActive(subItem.path) ? 'active' : ''}
                  sx={[
                    styles.groupItemButton,
                    {
                      display: isRightUser(subItem.users) ? 'flex' : 'none'
                    }
                  ]}
                  onClick={() => {
                    handleClickLink(subItem);
                  }}
                >
                  <ListItemText
                    sx={styles.menuItemText}
                    primary={subItem.title}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItemButton
          className={isActive(item.path) ? 'active' : ''}
          sx={[
            styles.submenuItemButton,
            { display: isRightUser(item.users) ? 'flex' : 'none' }
          ]}
          onClick={() => {
            handleClickLink(item);
          }}
        >
          <ListItemText sx={styles.menuItemText} primary={item.title} />
        </ListItemButton>
      )}
    </>
  );
};
