import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';

export interface IStylesType {
  [key: string]: React.CSSProperties | IStylesType;
}

export const styles: IStylesType = {
  menuListContainer: {
    overflowY: 'auto',
    flex: 1,
    width: '100%',
    height: '100%'
  },
  menuItemButton: {
    gap: '0.5rem',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#aeaeae29',
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: '#fff !important'
      }
    },
    '&.active': {
      backgroundColor: PureLightTheme.colors.primary.main,
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: '#fff !important'
      }
    }
  },
  menuItemIcon: {
    minWidth: '0px',
    '&.MuiListItemIcon-root .MuiSvgIcon-root': {
      color: '#dfdfdf'
    }
  },
  menuItemText: {
    '& > span': {
      fontWeight: '500',
      color: '#dfdfdf'
    }
  },
  collapseContainer: {
    marginLeft: '28px',
    borderLeft: '1px solid #aeaeae29'
  },
  submenuItemButton: {
    '&:hover': {
      backgroundColor: '#aeaeae29',
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: '#fff !important'
      }
    },
    '&.active': {
      backgroundColor: PureLightTheme.colors.primary.main,
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      }
    }
  },
  subMenuGroupButton: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#aeaeae29',
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: '#fff !important'
      }
    },
    '&.active': {
      backgroundColor: PureLightTheme.colors.primary.main,
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      }
    },
    '&.MuiListItemButton-root::before': {
      position: 'absolute',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      top: 0,
      bottom: 0,
      left: '-3.5px',
      backgroundColor: '#fff',
      content: '""',
      marginTop: 'auto',
      marginBottom: 'auto',
      zIndex: 1
    }
  },
  groupCollapseContainer: {
    marginLeft: '-2.5px',
    borderLeft: '4px solid #aeaeae29'
  },
  groupItemButton: {
    '&:hover': {
      backgroundColor: '#aeaeae29',
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: '#fff !important'
      }
    },
    '&.active': {
      backgroundColor: PureLightTheme.colors.primary.main,
      '& .MuiListItemText-root > span': {
        color: '#fff !important'
      }
    }
  },
  menuItemGeneralSettingText: {
    '& > span': {
      fontWeight: '500',
      color: '#1c2437',
      marginTop: '0px',
      marginBottom: '0px'
    }
  },
  menuItemGeneralSettingButton: {
    padding: '2px'
  }
};
