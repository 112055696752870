import { MenuIcons } from './MenuIcons';
import { UserRoles } from '../../../../constants/user-roles';

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[] | IGroupMenu[];
  permissions?: string[];
  users?: string[];
}

export interface IGroupMenu extends IMenuItem {
  isGroup?: boolean;
  menuItems?: IMenuItem[];
  permissions?: string[];
  users?: UserRoles[];
}

const MenuItems: IMenuItem[] = [
  {
    path: '/app/dashboard',
    title: 'Dashboard',
    Icon: MenuIcons.Dashboard,
    users: [UserRoles.Admin, UserRoles.Moderator]
  },
  {
    path: '/app/guides',
    title: 'Guides',
    Icon: MenuIcons.Guides,
    users: [UserRoles.Admin, UserRoles.Moderator]
  },
  {
    path: '/app/tours',
    title: 'Tours',
    Icon: MenuIcons.Tours,
    users: [UserRoles.Admin, UserRoles.Moderator]
  },
  {
    path: '/app/tourists',
    title: 'Tourists',
    Icon: MenuIcons.Tourists,
    users: [UserRoles.Admin, UserRoles.Moderator]
  },
  {
    path: '/app/reviews-ratings',
    title: 'Reviews & Ratings',
    Icon: MenuIcons.ReviewsAndRatings,
    users: [UserRoles.Admin]
  },
  {
    path: '/app/users',
    title: 'Users',
    Icon: MenuIcons.Users,
    users: [UserRoles.Admin]
  },
  {
    path: '/app/transaction',
    title: 'Transaction',
    Icon: MenuIcons.Transaction,
    users: [UserRoles.Admin, UserRoles.Moderator]
  },
  {
    path: '/app/settings',
    title: 'Settings',
    Icon: MenuIcons.Settings,
    users: [UserRoles.Admin],
    subMenu: [
      {
        path: '/app/settings/tour-categories',
        title: 'Tour Categories'
      },
      {
        path: '/app/settings/languages',
        title: 'Language'
      },
      {
        path: '/app/settings/faq',
        title: 'FAQs'
      },
      {
        path: '/app/settings/terms',
        title: 'Terms of Use'
      }
    ]
  }
];

export default MenuItems;
