import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/styles.module.css';
import './assets/styles/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from './contexts/JwtAuthContext';
import { DrawerProvider } from './contexts/DrawerContext';
import { ModalProvider } from './contexts/ModalDialogContext';

(ReactDOM.render as any)(
  <HelmetProvider>
    <AuthProvider>
      <SidebarProvider>
        <BrowserRouter>
          <DrawerProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </DrawerProvider>
        </BrowserRouter>
      </SidebarProvider>
    </AuthProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
