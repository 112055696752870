import { FC, createContext, useContext, useState } from 'react';

interface IDrawerPosition {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
}

export interface IDrawerContext {
  showDrawer: IDrawerPosition;
  drawerId?: string;
  loadingDrawerContent?: boolean;
  setDrawerId: (drawerId: string) => void;
  setLoadingDrawerContent: (loading: boolean) => void;
  setShowDrawer: React.Dispatch<React.SetStateAction<IDrawerPosition>>;
}

export const DrawerContext = createContext<IDrawerContext | undefined>(
  undefined
);

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error(
      'useDrawerContext must be used within a DrawerContextProvider'
    );
  }

  return context;
};

export const DrawerProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [drawerId, setDrawerId] = useState<string | undefined>(undefined);
  const [showDrawer, setShowDrawer] = useState<IDrawerPosition>({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [loadingDrawerContent, setLoadingDrawerContent] =
    useState<boolean>(false);

  const DrawerContextProvider = DrawerContext.Provider as any;
  return (
    <DrawerContextProvider
      value={{
        drawerId,
        setDrawerId,
        showDrawer,
        setShowDrawer,
        loadingDrawerContent,
        setLoadingDrawerContent
      }}
    >
      {children}
    </DrawerContextProvider>
  );
};
