import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { RouterLazyLoader } from './router-loader';

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace={true} />
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Login />
          </GuestGuard>
        )
      },
      {
        path: '*',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Status404 />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <SidebarLayout />,
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/app/dashboard" replace={true} />
      },
      {
        path: 'dashboard',
        element: <RouterLazyLoader.Dashboard />
      },
      {
        path: 'guides',
        element: <RouterLazyLoader.Guides />
      },
      {
        path: 'guides/:id',
        element: <RouterLazyLoader.GuideDetails />
      },
      {
        path: 'tours',
        element: <RouterLazyLoader.Tours />
      },
      {
        path: 'tours/:id',
        element: <RouterLazyLoader.TourDetails />
      },
      {
        path: 'tourists',
        element: <RouterLazyLoader.Tourists />
      },
      {
        path: 'users',
        element: <RouterLazyLoader.Moderator />
      },
      {
        path: 'transaction',
        element: <RouterLazyLoader.Transactions />
      },
      {
        path: 'settings',
        children: [
          {
            path: 'tour-categories',
            element: <RouterLazyLoader.TourCategories />
          },
          {
            path: 'languages',
            element: <RouterLazyLoader.Languages />
          }
        ]
      }
    ]
  }
];

export default routes;
